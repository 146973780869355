import React from 'react'

import { assign, pick, map, concat, uniqBy, groupBy, fromPairs } from 'lodash'

import GenModal from 'Gen/Modal/component'
import GenDirectionalButtons from 'DirectionalButtons/component'
import GenLoadingSpinner from 'LoadingSpinner/component'

import {checkVisible} from 'helpers/check_visibility'
import {full_url} from 'helpers/url'
import * as classNames from "classnames";
import Select from 'react-select';

const FILTERS_SETTINGS = [
  {
    title: "Presenting Problem",
    forKey: "for_presenting_problem",
    optionsKey: "presentingProblemOptions",
    filterGroup: 1,
  },
  {
    title: "Final Diagnosis",
    forKey: "for_final_diagnosis",
    optionsKey: "finalDiagnosisOptions",
    filterGroup: 1,
  },
  {
    title: "Additional Diagnosis",
    forKey: "for_additional_diagnosis",
    optionsKey: "additionalDiagnosisOptions",
    filterGroup: 1,
  },
  {
    title: "Illness Script Document",
    forKey: "for_illness_script",
    optionsKey: "illnessScriptOptions",
    shouldHide: (context) => context !== 'RC',
    filterGroup: 1,
  },
  {
    title: "Aquifer Signature Content",
    forKey: "for_course_name",
    optionsKey: "aquiferContentOptions",
    filterGroup: 2,
  },
  {
    title: "Clinical Disciplines",
    forKey: "for_clinical_discipline",
    optionsKey: "clinicalDisciplinesOptions",
    filterGroup: 2,
  },
  {
    title: "Clinical Focus",
    forKey: "for_clinical_focus",
    optionsKey: "clinicalFocusOptions",
    filterGroup: 2,
  },
  {
    title: "System",
    forKey: "for_system",
    optionsKey: "systemOptions",
    filterGroup: 2,
  },
  {
    title: "Basic Science Disciplines",
    subtitle: (optionsCount) => <small style={{fontSize: "8pt"}}>{optionsCount === 0 ? '(No Subscribed Content)' : ""}</small>,
    forKey: "for_basic_science_discipline",
    optionsKey: "basicScienceDisciplinesOptions",
    filterGroup: 2,
  },
  {
    title: "Clinical Location",
    forKey: "for_clinical_location",
    optionsKey: "clinicalLocationOptions",
    filterGroup: 2,
  },
  {
    title: "Age Groups",
    forKey: "for_age_groups",
    optionsKey: "ageGroupsOptions",
    filterGroup: 2,
  },
  {
    title: "Aquifer Signature Cases",
    forKey: "for_signature_case",
    optionsKey: "signatureCaseOptions",
    shouldHide: (context) => context !== 'RC',
    filterGroup: 2,
  },
]

export default class GenList extends React.Component {
  static defaultProps = {
    historyPersistance: false,
    defaultItemsPerPage: 12,
    isInfiniteScroll: false,
    permanentFilters: [],
    pinnedItems: []
  };

  constructor(props, context) {
    super(props, context);
    var defaults = {
      currentItems: null,
      itemsMap: {},
      currentItemCountMsg: null,
      itemsPerPage: props.defaultItemsPerPage,
      pageNumber: 0,
      gettingNewPage: false,
      itemsLastRecieved: null,
      searchValue: "",
      loSearchValue: "",
      searchTimer: null,
      loSearchTimer: null,
      sortValue: null,
      sortDirection: null,
      activeFilters: {},
      activeGenericFilters: {},
      selectionBasketModalOpen: false,
      moreScrollId: Math.floor(Math.random() * 0xFFFF),
      activeDynamicFilters: {},
      filterOptions: fromPairs(FILTERS_SETTINGS.map((filterSetting) => [filterSetting.optionsKey, []]))
    };

    var history = this.getHistoryState();
    var merged = assign(defaults, history);

    this.state = merged;
  }

  createFilterOptions = (filterType) => {
    let documentLookupTable = this.props.documentLookupTable
    let arrayOptions = []
    let uniqueCount = {}
    Object.keys(documentLookupTable).forEach((key) => {
      arrayOptions = arrayOptions.concat(documentLookupTable[key][filterType])
    })
    arrayOptions.forEach(function(i) { uniqueCount[i] = (uniqueCount[i]||0) + 1;});
    return Object.keys(uniqueCount).map ((k) => [`${k} [${uniqueCount[k]}]`, k]).sort()
  }

  componentDidMount() {
    this.getUpdatedList();

    if (this.props.documentLookupTable){
      this.setState({
        filterOptions: fromPairs(FILTERS_SETTINGS.map((filterSetting) => [filterSetting.optionsKey, this.createFilterOptions(filterSetting.title)]))
      });
    }

    if (Object.keys(this.state.activeDynamicFilters).length > 0) {
      const allDocumentsKeys = Object.keys(this.props.documentLookupTable)
      const scopeHash = fromPairs(FILTERS_SETTINGS.map((filterSetting) => [filterSetting.forKey, [filterSetting.title, filterSetting.optionsKey]]))
      let newFilterOptions = {}

      Object.keys(scopeHash).forEach(key => {
        let newClonedFilter = Object.assign({}, this.state.activeDynamicFilters)
        delete newClonedFilter[key]
        const selectedDocuments = Object.keys(newClonedFilter).length === 0 ? allDocumentsKeys : this.collectDocuments(newClonedFilter)
        newFilterOptions[scopeHash[key][1]] = this.computeStateFilterOptions(selectedDocuments, scopeHash[key][0])
      })

      this.setState({filterOptions: newFilterOptions})
    }

    if (this.props.isInfiniteScroll) {
      window.addEventListener("scroll", this.checkVisibilityForMore.bind(this));
      setTimeout(this.checkVisibilityForMore.bind(this),500);
    }
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearTimeout(this.state.searchTimer);
    }

    window.removeEventListener("scroll", this.checkVisibilityForMore.bind(this));
  }

  // search=string
  // page=int
  // sort_value=string
  // sort_order=string
  //
  getHistoryState = () => {
    var state = history.state;
    if (state === null) {
      state = {};
    }
    return(pick(state, ["searchValue", "loSearchValue", "sortValue", "sortDirection", "pageNumber", "activeFilters", "activeDynamicFilters"]));
  };

  setHistoryState = () => {
    var currentState = history.state;
    var newPartial = pick(this.state, ["searchValue", "loSearchValue", "sortValue", "sortDirection", "pageNumber", "activeFilters", "activeDynamicFilters"]);
    var newState = assign(currentState, newPartial);

    history.replaceState(newState, document.title);
  };

  handleSearchChange = (e) => {
    this.setState({searchValue: e.target.value,
                   currentItems: null,
                   gettingNewPage: false,
                   pageNumber: 0});
    if (this.state.searchTimer) { clearTimeout(this.state.searchTimer); }
    this.setState({searchTimer: setTimeout(this.updateSearchWithTimer, 1000)});
  };

  handleLOSearchChange = (e) => {
    this.setState({loSearchValue: e.target.value,
                   currentItems: null,
                   gettingNewPage: false,
                   pageNumber: 0});
    if (this.state.loSearchTimer) { clearTimeout(this.state.loSearchTimer); }
    this.setState({loSearchTimer: setTimeout(this.updateSearchWithTimer, 1000)});
  };

  handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  updateSearchWithTimer = () => {
    // this.updateUSC();
    this.getUpdatedList();
    this.setState({timer: null});
  };

  updateSort = (newSortValue, newSortDirection) => {
    this.setState({ sortValue: newSortValue,
                    sortDirection: newSortDirection,
                    currentItems: null,
                    gettingNewPage: false,
                    pageNumber: 0 }, this.getUpdatedList);
    return false
  };

  handleFilterChange = (that, newKey) => {
    return(function(newSelect){
      let newValue = newSelect.target.value;

      let currentActiveFilters = that.state.activeFilters;
      let newActiveFilters = {};
      for (var attr in currentActiveFilters) {
        if (attr != newKey) {
          newActiveFilters[attr] = currentActiveFilters[attr];
        }
      }

      if (newValue != "All") {
        newActiveFilters[newKey] = newValue;
      }

      that.setState({ activeFilters: newActiveFilters,
                      currentItems: null,
                      gettingNewPage: false,
                      pageNumber: 0 }, that.getUpdatedList);
    });
  };

  computeStateFilterOptions = (selectedDocuments, filterType) => {
    let documentLookupTable = this.props.documentLookupTable
    let arrayOptions = []
    let otherOptions = []
    let uniqueCount = {}
    Object.keys(documentLookupTable).forEach((key) => {
      if(selectedDocuments.includes(key)){
        arrayOptions = arrayOptions.concat(documentLookupTable[key][filterType])
      }
      else{
        otherOptions = otherOptions.concat(documentLookupTable[key][filterType])
      }
    })
    arrayOptions.forEach(function(i) { uniqueCount[i] = (uniqueCount[i]||0) + 1;});
    otherOptions.forEach(function(i) {
      if(!arrayOptions.includes(i)) {
        uniqueCount[i] = 0
      }
    })
    return Object.keys(uniqueCount).map ((k) => [`${k} [${uniqueCount[k]}]`, k]).sort()
  }

  collectDocuments = (newActiveDynamicFilters) => {
    let documentLookupTable = this.props.documentLookupTable
    let scopeHash = fromPairs(FILTERS_SETTINGS.map((filterSetting) => [filterSetting.forKey, filterSetting.title]))

    let documentArray = []
    let documentCountHash = {}
    let selectedDocuments = []
    Object.keys(newActiveDynamicFilters).forEach((key) => {
      Object.keys(documentLookupTable).forEach((documentId) => {
        if(Array.isArray(documentLookupTable[documentId][scopeHash[key]])) {
          if(Array.isArray(newActiveDynamicFilters[key])){
            if(newActiveDynamicFilters[key].some((elem) => documentLookupTable[documentId][scopeHash[key]].includes(elem))){
              documentArray.push(documentId)
            }
          }else{
            if(documentLookupTable[documentId][scopeHash[key]].includes(newActiveDynamicFilters[key])){
              documentArray.push(documentId)
            }
          }
        }
      })
    })
    documentArray.forEach(function(i) { documentCountHash[i] = (documentCountHash[i]||0) + 1;});
    Object.keys(documentCountHash).forEach((key) => {
      if(documentCountHash[key]==Object.keys(newActiveDynamicFilters).length){
        selectedDocuments.push(key)
      }
    })

    return selectedDocuments
  }

  calculateDynamicOptions = (newActiveDynamicFilters, currentFilters, newKey, newValue) => {
    let scopeHash = fromPairs(FILTERS_SETTINGS.map((filterSetting) => [filterSetting.forKey, [filterSetting.title, filterSetting.optionsKey]]))

    const selectedValueLabel = this.state.filterOptions?.[scopeHash[newKey][1]].find(z => z[1] === newValue)?.[0] || 'All'
    const uniqueCount = parseInt(selectedValueLabel.substring(
      selectedValueLabel.indexOf("[") + 1,
      selectedValueLabel.lastIndexOf("]")
    )) || 0;
    let newFilterOptions = {}

    if (Object.keys(currentFilters).length === 0) {
      newFilterOptions[scopeHash[newKey][1]] = this.createFilterOptions(scopeHash[newKey][0])
    } else {
      const currentFilterDocuments = this.collectDocuments(currentFilters)
      newFilterOptions[scopeHash[newKey][1]] = this.computeStateFilterOptions(currentFilterDocuments, scopeHash[newKey][0])
    }

    if (uniqueCount !== 0 || newValue==='All') {
      const allDocumentsKeys = Object.keys(this.props.documentLookupTable)

      delete scopeHash[newKey]
      Object.keys(scopeHash).forEach(key => {
        let newClonedFilter = Object.assign({}, newActiveDynamicFilters)
        delete newClonedFilter[key]
        const selectedDocuments = Object.keys(newClonedFilter).length === 0 ? allDocumentsKeys : this.collectDocuments(newClonedFilter)
        newFilterOptions[scopeHash[key][1]] = this.computeStateFilterOptions(selectedDocuments, scopeHash[key][0])
      })
    } else {
      newFilterOptions = { ...this.state.filterOptions,...newFilterOptions}
    }
    this.setState({filterOptions: newFilterOptions})
  }

  handleDynamicFilterChange = (that, newKey) => {
    return(function(newSelect){
      let newValue
      if(Array.isArray(newSelect)){
        newValue = newSelect.map((item) => item.value);
      }else{
        newValue = newSelect?.value || "All";
      }
      let currentActiveDynamicFilters = that.state.activeDynamicFilters;
      let selectedFilters = that.state.activeDynamicFilters;
      if(Object.keys(selectedFilters).includes(newKey)){
        delete selectedFilters[newKey]
      }
      let newActiveDynamicFilters = {};
      for (var attr in currentActiveDynamicFilters) {
        if (attr != newKey) {
          newActiveDynamicFilters[attr] = currentActiveDynamicFilters[attr];
        }
      }

      if (newValue != "All") {
        newActiveDynamicFilters[newKey] = newValue;
      }

     if(Object.keys(newActiveDynamicFilters).length === 0 || (Object.values(newActiveDynamicFilters).every(v => Array.isArray(v) ? v.includes("All"): v=="All"))){
        that.setState({
          filterOptions: fromPairs(FILTERS_SETTINGS.map((filterSetting) => [filterSetting.optionsKey, that.createFilterOptions(filterSetting.title)]))
        })
      } else {
        if(Array.isArray(newValue)){
          newValue.map((nv) => that.calculateDynamicOptions(newActiveDynamicFilters,selectedFilters,newKey,nv))
        }else{
          that.calculateDynamicOptions(newActiveDynamicFilters,selectedFilters,newKey,newValue)
        }
      }

      that.setState({
        activeDynamicFilters: newActiveDynamicFilters,
        currentItems: null,
        gettingNewPage: false,
        pageNumber: 0 }, that.getUpdatedList
      );
    });
  };

  handleGenericFilterChange = (that, newKey) => {
    return(function(newSelect){
      let newValue = newSelect.target.value;

      let currentActiveGenericFilters = that.state.activeGenericFilters;
      let newActiveGenericFilters = {};
      for (var attr in currentActiveGenericFilters) {
        if (attr != newKey) {
          newActiveGenericFilters[attr] = currentActiveGenericFilters[attr];
        }
      }

      if (newValue != "All") {
        newActiveGenericFilters[newKey] = newValue;
      }

      that.setState({ activeGenericFilters: newActiveGenericFilters,
                      currentItems: null,
                      gettingNewPage: false,
                      pageNumber: 0 }, that.getUpdatedList);
    });
  };

  moreClick = () => {
    this.setState({ pageNumber: this.state.pageNumber + 1,
                    gettingNewPage: true}, this.getAdditionalPage);
  };

  checkVisibilityForMore = () => {
    const elem = document.getElementById(this.state.moreScrollId)

    if (elem) {
      if (checkVisible(elem)) {
        this.moreClick()
      }
    }
  }

  dataForQuery = () => {
    let data = {};

    if (this.props.ids) {
      data.ids = this.props.ids;
    }

    if (this.props.notIds) {
      data.not_ids = this.props.notIds;
    }

    if (this.state.searchValue && (this.state.searchValue !== "")) {
      data.search = this.state.searchValue;
    }

    if (this.state.loSearchValue && (this.state.loSearchValue !== "")) {
      data.lo_search = this.state.loSearchValue;
    }

    if (this.state.sortValue) {
      data.sort_order = (this.state.sortDirection === "descending") ? "DESC" : "ASC";
      data.sort_by = this.state.sortValue;
    }


    if (this.state.itemsPerPage) {
      if (this.state.currentItems === null) {
        data.page = 0;
        data.per_page = (this.state.pageNumber + 1) * this.state.itemsPerPage;
      } else {
        data.page = this.state.pageNumber;
        data.per_page = this.state.itemsPerPage;
      }
    }

    let filterValues = [];
    for(var key in this.state.activeFilters) {
      filterValues.push(this.state.activeFilters[key]);
    }
    if (filterValues.length > 0) {
      data.filters = filterValues;
    }

    if (Object.keys(this.state.activeDynamicFilters).length > 0) {
      data.dynamic_filters = JSON.stringify(this.state.activeDynamicFilters);
    }

    if (this.props.permanentFilters.length > 0) {
      data.where = JSON.stringify(this.props.permanentFilters)
    }

    let genericFilterValues = [];
    for(var key in this.state.activeGenericFilters) {
      let title = key
      let selectedOption = this.state.activeGenericFilters[title]
      let activeGenericFilter = this.props.genericFilterOptions.find((genericFilter) =>{
        return genericFilter['title'] === title
      })

      if (activeGenericFilter) {
        let genericFilterWhere = activeGenericFilter['options'][selectedOption]

        if (genericFilterWhere) {
          genericFilterValues.push(genericFilterWhere)
        }
      }
    }

    if (genericFilterValues.length > 0) {
      data.generic_filters = JSON.stringify(genericFilterValues);
    }

    return data;
  };

  getPage(successCallback) {
    if(this.ongoingFetchController) {
      this.ongoingFetchController.abort();
    }

    // ("AbortController" in window) gaurds againt legacy browsers that don't support AbortController
    const controller = ("AbortController" in window) && new AbortController();
    this.ongoingFetchController = controller;

    const queryParams = new URLSearchParams(this.dataForQuery());
    const url = `${full_url(this.props.itemsURL)}?${queryParams}`;

    fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      signal: controller.signal
    })
      .then(response => response.json())
      .then((json) => successCallback.bind(this)(json))
      .catch((error) => console.log(error));
  };

  getPageCount = (successCallback) => {
    if (!this.props.itemCountURL) {
      return;
    }

    $.ajax({
      type: "GET",
      url: full_url(this.props.itemCountURL),
      dataType: 'json',
      data: this.dataForQuery(),
      contentType: 'application/json',
      success: successCallback.bind(this),
      error: function(xhr, status, error) {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
      }
    });
  };

  mergeDocumentItems = (newItems, itemsMap) => {
    if (itemsMap && Array.isArray(newItems)) {
      newItems.forEach(item => {
        // Repeat the +replaceAll+ call multiple times to accommodate nested spans.
        const searchStrippedTitle = this.stripSearchSpans(item.title)

        const reducedItem = {
          title:                 searchStrippedTitle,
          url:                   item.url && item.url.toString().replace(/.json$/i, ''),
          prerequisite_document: item.prerequisite_document,
        };
        itemsMap[item.id] = reducedItem
      })
    }

    // ADD PREREQUISITE DOCUMENTS TO ITEMS MAP SO THEY CAN BE ADDED TO SELECTION BASKET
    const prerequisiteDocuments = newItems.map(newItem => newItem.prerequisite_document).filter(x => x);
    if (prerequisiteDocuments.length > 0) {
      itemsMap = this.mergeDocumentItems(prerequisiteDocuments, itemsMap)
    }

    return itemsMap
  }

  stripSearchSpans(html) {
    if (!html) { return html }

    let htmlAfterOneStrip = html.replace(/<span class="search-hit">(.*?)<\/span>/, "$1")
    if (htmlAfterOneStrip == html) { return html }

    // Recurse
    return this.stripSearchSpans(htmlAfterOneStrip)
  }

  getUpdatedList = () => {
    this.getPage(function(result) {
      if (this.props.historyPersistance) { this.setHistoryState(); }
      this.setState({currentItems: uniqBy(result, 'id'),
                     itemsMap: this.mergeDocumentItems(result, this.state.itemsMap),
                     itemsLastRecieved: result?.length}, () => {
                       setTimeout(() => {
                         if (this.props.isInfiniteScroll) {
                           this.checkVisibilityForMore()
                         }
                       }, 300)
                     });
    });
    this.getPageCount(function(result) {
      this.setState({currentItemCountMsg: result});
    });
  };

  getAdditionalPage = () => {
    this.getPage(function(result) {
      // To prevent error caused when user changes filters making this.state.currentItems null before additional page is loaded
      if (!this.state.currentItems) { return; }

      if (this.props.historyPersistance) { this.setHistoryState(); }
      const currentItems = uniqBy(concat(this.state.currentItems, result), 'id')
      this.setState({
          currentItems,
          itemsMap: this.mergeDocumentItems(result, this.state.itemsMap),
          itemsLastRecieved: result.length,
          gettingNewPage: false
        },
        () => setTimeout(this.props.isInfiniteScroll ? this.checkVisibilityForMore : () => {}, 300)
      )
    });
  };

  moreItems = () => {
    if (!this.state.currentItems) return null

    const MoreItemsContainer = this.props.tableHeader ? 'tr' : 'div'
    const InfiniteScrollContainer = this.props.tableHeader ? 'td' : 'div'

    if (this.state.itemsLastRecieved !== null &&
      this.state.itemsLastRecieved < this.state.itemsPerPage){

      if (this.props.isInfiniteScroll) return <MoreItemsContainer />

      return <MoreItemsContainer className="gen-list-pagination">
        <button className="gen-list-more-button disabled">More</button>
      </MoreItemsContainer>
    } else if (this.state.gettingNewPage) {
      return <MoreItemsContainer><GenLoadingSpinner/></MoreItemsContainer>
    }

    return <MoreItemsContainer className="gen-list-pagination">
        { this.props.isInfiniteScroll ? (
          <InfiniteScrollContainer>
            <span id={this.state.moreScrollId} className="more-scroll"></span>
          </InfiniteScrollContainer>
        ) : (
          <button onClick={this.moreClick} className="gen-list-more-button">More</button>
        )}
      </MoreItemsContainer>
  }

  handleToggleSelectionBasketModalOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      selectionBasketModalOpen: !this.state.selectionBasketModalOpen
    });
  };

  selectionBasketItem = (documentId) => {
    const docItem = this.state.itemsMap[documentId];

    const removeIdFromSelectionBasket = ({documentId}) => (e) =>{
      this.props.removeDocumentId(documentId)
    };

    return <tr key={documentId}>
      <td style={{paddingBottom: 0, paddingTop: '5px'}}>
        <a href={docItem.url} target="_blank">
          {docItem.title}
        </a>
      </td>
      {!this.hasAnySelectedSubservientDocuments(documentId) && (
        <td style={{paddingBottom: 0, paddingTop: '5px'}}>
          <button
            className={classNames('button', 'small')}
            onClick={removeIdFromSelectionBasket({documentId})}>
            Remove
          </button>
        </td>
      )}
    </tr>
  }

  hasAnySelectedSubservientDocuments = (documentId) => {
    return this.props.selectedDocuments?.some(selectedDocumentId => {
      return this.state.itemsMap[selectedDocumentId]?.prerequisite_document?.id === documentId
    })
  }


  deselectAll = () => {
    this.props.selected.forEach(documentId => this.props.removeDocumentId(documentId))
  }

  render() {
    let viewSelectionBasket = null
    let itemCountBox = null
    let searchBox = null
    let searchBoxForLO = null
    let filterRole = null
    let sortOptions = null
    let controlPanel = null
    let roleDropdown = null

    let modalHeader = null
    if (this.props.selectedDocuments?.length == 0) {
      modalHeader = <h5>
        You have no selected items.
        Click "Close" to continue to filter or search and add items.
      </h5>
    } else {
      if (this.props.selectedDocuments) {
        const showPrerequisiteWarning = this.props.selectedDocuments.some(documentId => this.hasAnySelectedSubservientDocuments(documentId));

        modalHeader = <h5>
          You have added the following items to create your course.
          {showPrerequisiteWarning ? " At least one of these is a case associated with a prerequisite case and the prerequisite case has also been added to your course." : " "}
          You may continue to filter or search and add items.
          Click the Create Course button when you have finalized your selections.
        </h5>
      }
    }


    let removeAllButton = null
    if (this.props.selectedDocuments && this.props.selectedDocuments.length != 0) {
      removeAllButton = <button style={{float: 'right'}} onClick={this.deselectAll}>Remove All</button>
    }

    let selectionBasketModal =
      <GenModal open={this.state.selectionBasketModalOpen} >
        <div className="gen-modal-panel">
          {modalHeader}
          <table>
            <tbody>
              <tr style={{borderBottom: '1px solid #ccc'}}>
                <td style={{width: '100%', padding: 0}} />
                <td style={{width: '90px', padding: 0}} />
              </tr>
              {this.props.selectedDocuments && this.props.selectedDocuments.map(documentId => this.selectionBasketItem(documentId))}
            </tbody>
          </table>

          {removeAllButton}
          <div style={{textAlign: 'center'}}>
            <button
              onClick={this.handleToggleSelectionBasketModalOpen}>
              Close
            </button>
          </div>
        </div>
      </GenModal>

    if (this.props.selected && this.props.selected.length > 0) {
      if (this.props.useSelectionBasket) {
        viewSelectionBasket = <button
          onClick={this.handleToggleSelectionBasketModalOpen}>
          {`View Selected Items (${this.props.selected.length})`}
        </button>
      } else {
        viewSelectionBasket = <span>{this.props.selected.length} selected</span>
      }
    }

    if (this.props.search || this.props.sortOptions || this.props.filterRole) {
      if (this.state.currentItemCountMsg) {
        itemCountBox = <div className="gen-list-item-count">
          {this.state.currentItemCountMsg}
        </div>
      }

      if (this.props.search) {
        searchBox = <form className="gen-list-search-bar" style={(this.props.searchByLO || this.props.coursePageUpgrade) && {float: 'right', marginRight: '20px'}} role="search">
          <input
            type="search"
            placeholder="Search..."
            value={this.state.searchValue}
            onChange={this.handleSearchChange}
            onKeyPress={this.handleSearchKeyPress}
          />
        </form>;
      }

      if (this.props.searchByLO) {
        searchBoxForLO = <form className="gen-list-search-bar" role="search">
          <h4>Learning Objectives</h4><br/>
          <input
            type="search"
            placeholder="Search By Learning Objective..."
            value={this.state.loSearchValue}
            onChange={this.handleLOSearchChange}
            onKeyPress={this.handleSearchKeyPress}
          />
        </form>;
      }

      if (this.props.sortOptions) {
        sortOptions = <GenDirectionalButtons
                          buttons={this.props.sortOptions}
                          selectedValue={this.state.sortValue || this.props.defaultSortValue}
                          selectedDirection={this.state.sortDirection || this.props.defaultSortDirection}
                          onUpdate={this.updateSort}/>;
      }


      controlPanel = <>
        {this.props.searchByLO && (
          <div className="gen-list-control-panel">
            {searchBoxForLO}
          </div>
        )}
        <div className="gen-list-control-panel" style={this.props.coursePageUpgrade && {boxShadow: 'none', border: 'none', paddingBottom: '23px'}}>
          {filterRole}
          {searchBox}
          {sortOptions}
          {itemCountBox}
        </div>
      </>
    }

    let activeFilters, activeGenericFilters
    let filterPanel = null
    if (this.props.filterOptions || this.props.genericFilterOptions) {
      activeFilters = this.state.activeFilters;
      activeGenericFilters = this.state.activeGenericFilters
      filterPanel = <div className="gen-list-filter-panel" style={this.props.coursePageUpgrade && {boxShadow: 'none', border: 'none'}}>
        {this.props.filterOptions && this.props.filterOptions.map(function(filter){
          let filterOut = <div key={filter.title} className="gen-list-filter">
            <h5>{filter.title}</h5>
            <select defaultValue={activeFilters[filter.title] || "All"} onChange={this.handleFilterChange(this, filter.title)}>
              <option value="All">All</option>
              {filter.options.map(function(option){
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </div>;

            return( filterOut );
          }, this)}
        {this.props.genericFilterOptions && this.props.genericFilterOptions.map(function(filter){
          let filterOut = <div key={filter.title} className="gen-list-filter">
            <h5><span className={this.props.coursePageUpgrade && "course-page-filter-title"}>{filter.title}</span></h5>
            <select defaultValue={activeGenericFilters[filter.title] || "All"} onChange={this.handleGenericFilterChange(this, filter.title)}>
              <option value="All">All</option>
              {Object.keys(filter.options).map(function(option){
                if (option === 'Invited') {
                  return(
                    <option key={option} value={option}>
                      Registration Pending
                    </option>
                  );
                } else {
                  return(
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                }
              })}
            </select>
          </div>;

            return( filterOut );
          }, this)
        }
      </div>;
    }

    let activeDynamicFilters
    let dynamicFilters = null
    let isMultiSelect = !this.props.isMultiSelect && {label: "All", value: "All"}

    if (this.props.documentLookupTable) {
      activeDynamicFilters = this.state.activeDynamicFilters;

      dynamicFilters = Object.entries(groupBy(FILTERS_SETTINGS, (filter) => filter.filterGroup)).map(([key, filterGroup]) => {
        const filters = filterGroup.map(filterSettings => {
          if (filterSettings.shouldHide && filterSettings.shouldHide(this.props.context)) { return null }

          const filterOptions = this.state.filterOptions[filterSettings.optionsKey]
          const subtitle = filterSettings.subtitle && filterSettings.subtitle(filterOptions.length)
          const activeFilters = activeDynamicFilters[filterSettings.forKey]

          return (
            <div className="gen-list-filter" style={{marginRight: "1.6%"}} key={filterSettings.title}>
              <h5>{filterSettings.title} {subtitle}</h5>
              <Select
                isMulti={this.props.isMultiSelect}
                placeholder="All"
                value={
                  activeFilters && Object.keys(activeFilters).length
                  ? [isMultiSelect, ...filterOptions.map((option) => ({ label: option[0], value: option[1] }))]
                    .filter(option => activeFilters.includes(option.value))
                  : isMultiSelect
                }
                onChange={this.handleDynamicFilterChange(this, filterSettings.forKey)}
                options={[isMultiSelect, ...filterOptions.map((option) => ({label: option[0], value: option[1]}))]}
                disabled={filterOptions.length===0}
              />
            </div>
          )
        })

        return (
          <div className="gen-list-filter-panel" key={key}>
            {filters}
          </div>
        )
      })
    }


    let pinnedItems = []
    if (this.props.pinnedItems.length > 0) {
      pinnedItems = this.props.pinnedItems.map((item) => {
        return(this.props.itemView(item));
      })
    }

    let listItems
    let tableStyle = this.props.tableStyle

    if (this.state.currentItems === null) {
      listItems = <GenLoadingSpinner/>;
    } else if (this.state.currentItems.length === 0) {
      listItems =
        <div className="gen-list-no-items">
          <h1>No Items</h1>
        </div>
    } else {
      let stripSearch = this.state.currentItems
      if(typeof stripSearch!=='undefined') {
        stripSearch.forEach(item => {
          if(typeof item.title!=='undefined') {
            item.title = item.title.replace(/<span class="search-hit">(.*?)<\/span>/, "$1")
          }
        })
      }
      listItems = map(stripSearch, (item) => this.props.itemView({...item, canRemove: !this.hasAnySelectedSubservientDocuments(item.id)}))
      if (this.props.tableHeader) {
        listItems =
          <table style={tableStyle}>
            <thead>
              {this.props.tableHeader}
            </thead>
            <tbody>
              {listItems}
            </tbody>
            <tfoot>
              {this.moreItems()}
            </tfoot>
          </table>
      } else {
        listItems = <div className="gen-list-items">{listItems}{this.moreItems()}</div>;
      }
    }

    let otherPages = <div className="gen-list">
                      {filterPanel}
                      {dynamicFilters}
                      {this.props.selectPanel && this.props.selectPanel(viewSelectionBasket)}
                      {controlPanel}
                      {pinnedItems}
                      {listItems}
                      {selectionBasketModal}
                    </div>

    let coursepage = <div className="gen-list">
                      <div style={{display: "inline-flex", width: "100%"}}>
                        <div style={{width: "70%"}}>
                          {filterPanel}
                        </div>
                        <div style={{width: "30%"}}>
                          {dynamicFilters}
                          {this.props.selectPanel && this.props.selectPanel(viewSelectionBasket)}
                          {controlPanel}
                        </div>
                      </div>
                      {pinnedItems}
                      {listItems}
                      {selectionBasketModal}
                    </div>

    let displayDiv = this.props.coursePageUpgrade ? coursepage : otherPages

    return(
      <div>
        {displayDiv}
      </div>
    );
  }
}
